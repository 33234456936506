import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App).use(router).mount('#app')




document.addEventListener("DOMContentLoaded", function(){
    const form = this.getElementById("form");
    form.addEventListener('submit', formSend);

    async function formSend(e){
        e.preventDefault();
        let formData = new FormData(form);

       
        let response = await fetch('sendmail.php',{
            method: 'POST',
            body:formData
        });
        if(response.ok){
            
            alert("Сообщение отправленно") ;
        }else{
            alert("Ошибка!!!!");
        }
    }
});

document.addEventListener("DOMContentLoaded", function(){
    const form = this.getElementById("form2");
    form.addEventListener('submit', formSend);

    async function formSend(e){
        e.preventDefault();
        let formData = new FormData(form);

       
        let response = await fetch('sendmail.php',{
            method: 'POST',
            body:formData
        });
        if(response.ok){
            
            alert("Сообщение отправленно");
        }else{
            alert("Ошибка!!!!");
        }
    }
});


document.addEventListener("DOMContentLoaded", function(){
    const form = this.getElementById("form3");
    form.addEventListener('submit', formSend);

    async function formSend(e){
        e.preventDefault();
        let formData = new FormData(form);

       
        let response = await fetch('sendmail.php',{
            method: 'POST',
            body:formData
        });
        if(response.ok){
            alert("Сообщение отправленно");
        }else{
            alert("Ошибка!!!!");
        }
    }
});