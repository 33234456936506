<template>
  <section class="hero">
    <div class="container text-center position-relative">
      <hr class="mb-4 mx-auto mt-0">
      <h3 class="mb-4">Ищите инструменты для развития бизнеса?</h3>
      <h1 class="mb-5"></h1>
      <h2><em>Маркетинговое Агентство Работающих Технологий</em></h2>
      <br>
      <h3 class="mb-5">
        это IT-агентство, адаптирующее современную концепцию BIGDATA под инструменты поисков клиентов для предпринимателей
      </h3>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        Увеличить продажи
      </button>
    </div>
  </section>

  <div class="container">


    <h2 class="mb-5 text-center mt-5">Наши решения для вашего бизнеса</h2>
    <div class="row">
      <div class="col-xxl-3 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-1.png" alt="Технология генерации клиентов" class="img-fluid mb-3">
        <h5 class="mb-2">
          Технология<br class="d-none d-lg-block">
          перехвата<br class="d-none d-lg-block">
          номеров
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Лидогенерация с 
      оплатой&nbsp;за&nbsp;клиента">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-2.png" alt="Лидогенерация c оплатой за клиента" class="img-fluid mb-3">
        <h5 class="mb-2">
          B2B <br class="d-none d-lg-block">
          лидогенерация<br class="d-none d-lg-block">

        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Лидогенерация с 
      оплатой&nbsp;за&nbsp;клиента">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-2.png" alt="Разработка рекламных кампаний с оплатой по KPI" class="img-fluid mb-3">
        <h5 class="mb-2">
          Разработка<br class="d-none d-xl-block">
          рекламных кампаний<br class="d-none d-xl-block">

        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Разработка 
      рекламных кампаний&nbsp;с&nbsp;оплатой по KPI">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-4.png" alt="Разработка продающих сайтов" class="img-fluid mb-3">
        <h5 class="mb-2">
          Разработка<br class="d-none d-xl-block">
          продающих<br class="d-none d-xl-block">
          сайтов
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Разработка 
      продающих&nbsp;с&nbsp;сайтов"> Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-5.png" alt="Внедрение CRM, автоматизация работы" class="img-fluid mb-3">
        <h5 class="mb-2">
          Внедрение CRM,<br class="d-none d-xl-block">
          автоматизация<br class="d-none d-xl-block">
          работы
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Внедрить автоматизацию
              в&nbsp;свой&nbsp;бизнес">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-6.png" alt="Комплекс виджетов для увеличения конверсии" class="img-fluid mb-3">
        <h5 class="mb-2">
          Комплекс виджетов<br class="d-none d-xl-block">
          для увеличения<br class="d-none d-xl-block">
          конверсии
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Комплекс виджетов
              для&nbsp;увеличения конверсии">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-7.png" alt="Упаковка бизнеса в франшизу, от 30 офисов" class="img-fluid mb-3">
        <h5 class="mb-2">
          Упаковка бизнеса<br class="d-none d-xl-block">
          в франшизу,<br class="d-none d-xl-block">
          от 30 офисов
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Упаковка
              бизнеса в франшизу,&nbsp;от&nbsp;30 офисов">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-8.png" alt="Генерация клиентов из соц. сетей" class="img-fluid mb-3">
        <h5 class="mb-2">
          Генерация<br class="d-none d-xl-block">
          клиентов<br class="d-none d-xl-block">
          из соц. сетей
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Генерация
              клиентов&nbsp;из&nbsp;соц.сетей">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-9.png" alt="Усилим работу вашего отдела продаж" class="img-fluid mb-3">
        <h5 class="mb-2">
          Усилим работу<br class="d-none d-xl-block">
          вашего отдела<br class="d-none d-xl-block">
          продаж
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Усилить работу
              отдела продаж">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-10.png" alt="Система голосовых рассылок" class="img-fluid mb-3">
        <h5 class="mb-2">
          Система<br class="d-none d-xl-block">
          голосовых<br class="d-none d-xl-block">
          рассылок
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Система
              голосовых&nbsp;рассылок">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-11.png" alt="Подбор эффективных сотрудников" class="img-fluid mb-3">
        <h5 class="mb-2">
          Подбор<br class="d-none d-xl-block">
          эффективных<br class="d-none d-xl-block">
          сотрудников
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Подбор
              эффективных&nbsp;сотрудников">Оставить заявку</a>
      </div>
      <div class="col-xxl-3 offset-xxl-1 col-lg-4 col-sm-6 mb-5">
        <img src="../assets\icons\item-12.png" alt="Продающие видео для бизнеса" class="img-fluid mb-3">
        <h5 class="mb-2">
          Продающие<br class="d-none d-xl-block">
          видео<br class="d-none d-xl-block">
          для бизнеса
        </h5>
        <a href="#" class="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
          data-bs-title="Продающие видео
              для&nbsp;бизнеса">Оставить заявку</a>

      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ServicesView',
  props: {
    msg: String
  }
}
</script>
  
<style scoped>
body {
  color: white;
}

p,
h2,
h5 {
  color: white;
}

p {
  font-size: 16pt;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}

.btn {
  padding: 14px;
  min-height: 48px;
  border-radius: 10px;
  background-color: #f4b859;
  border: 1px solid #f4b859;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #282828;
  cursor: pointer;
}

input[type=text],
input[type=email] {
  padding: 25px;
  width: 100%;
  max-width: 380px;
  height: 76px;
  border-radius: 10px;
  background-color: #acacac;
  text-align: center;
  color: #e4e4e4;
  font-size: 20px;
  line-height: 24px;
}

@media (min-width: 992px) {
  .hero {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .h1,
  h1 {
    font-size: 2.5rem;
  }
}

.hero {

  padding-top: 100px;
  padding-bottom: 30px;
  background-image: url(../assets/Service/hero-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.hero hr {
  width: 87px;
  height: 4px;
  background-color: #f4b859;
  opacity: 1;
}

hr:not([size]) {
  height: 4px;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

h1 em {
  font-style: normal;
  color: #f4b859;
}

h2 em {
  font-style: normal;
  color: #f4b859;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

таблица стилей агента пользователя em {
  font-style: italic;
}

h1 {
  font-weight: 700;
  font-size: 40px;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h5 {
  font-size: 25px;
  font-weight: 500;
  color: white !important;
}</style>