<template>
  <div class = "container">
  <div class="section-2">
      <div class="site-width">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="title" style="font-size: 36pt;">
              Наша компания не случайно <br>
              называется 
              <div class = "text-center"><h2 class = "text-start" style="font-size: 36pt;" >
                <em class = "nmart">М</em>аркетинговое <br> 
                <em class = "nmart">А</em>гентство<br> 
                <em class = "nmart">Р</em>аботающих <br>
                <em class = "nmart">Т</em>ехнологий<br></h2>
              </div>
            </h2>
            <p>
              Рынок интернет-рекламы огромен и&nbsp;существует множество подрядчиков,
              которые рассказывают много умных слов о том как работает привлечение
              клиентов, однако, часто, сотрудничество с такими компании оканчивается
              неудачно (по статистике, у 82% наших клиентов был неудачный опыт работы с
              маркетинговыми агентствами до нас)
            </p>
          </div>
          <div class="col-lg-6 text-center">
            <img src="../assets\icons\section2.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="section-3">
      <div class="site-width">
        <div class="row">
          <div class="col-lg-5 order-2 order-lg-0 text-center">
            <img src="../assets\icons\section3.png" alt="">
          </div>
          <div class="col-lg-7">
            <h2 class="title">
              В 2023 году мы создали компанию, у&nbsp;которой сформировали простую,
              но&nbsp;важную цель -
              <span style = "color : #f4b859">обеспечивать реальный результат для бизнеса</span>
            </h2>
            <p>
              И это не просто красивые слова, как пишут многие, а наш подход к работе. Мы
              не берем деньги за “показы”, “клики” или “создание объявлений”. Нам платят
              только за конкретный результат в виде новых потенциальных клиентов, которых
              мы обеспечиваем для бизнеса.
              <br><br>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section-4">
      <div class="site-width">
        <div class="title">
          Как нам удается показывать <span>такие результаты? </span>
        </div>
        <div class="desk">
          Все дело в том, что в основе нашего бизнеса лежит собственный IT-продукт,
          который позволяет существенно увеличивать продажи через интернет, за счет того,
          что наш сервис позволяет вам получать контакты тех клиентов, кто заходил к вам
          на сайт, но не оставил на нем заявку.
        </div>
      </div>
    </div>

    <div class="site-width mb-5">
        <div class="title">
          Наш подход к привлечению клиентов уже оценили более <span>27,000 клиентов</span>
        </div>

        <div class="desk">Мы гордимся сотрудничеством с такими брендами как:</div>

        <div class="logos">
          <img src="../assets/AboutPage/logo/logo-1.png">
          <img src="../assets/AboutPage/logo/logo-2.png">
          <img src="../assets/AboutPage/logo/logo-3.png">
          <img src="../assets/AboutPage/logo/logo-4.png">
          <img src="../assets/AboutPage/logo/logo-5.png">
          <img src="../assets/AboutPage/logo/logo-6.png">
          <img src="../assets/AboutPage/logo/logo-7.png">
          <img src="../assets/AboutPage/logo/logo-8.png">
          <img src="../assets/AboutPage/logo/logo-9.png">
          <img src="../assets/AboutPage/logo/logo-10.png">
          <img src="../assets/AboutPage/logo/logo-11.png">
          <img src="../assets/AboutPage/logo/logo-12.png">
          <img src="../assets/AboutPage/logo/logo-13.png">
          <img src="../assets/AboutPage/logo/logo-14.png">
          <img src="../assets/AboutPage/logo/logo-15.png">
          <img src="../assets/AboutPage/logo/logo-16.png">
          <img src="../assets/AboutPage/logo/logo-17.png">
          <img src="../assets/AboutPage/logo/logo-18.png">
        </div>

        <div class="text-center">
          <a href="https://docs.google.com/spreadsheets/d/1f4z0zePJgpCsbsLCQl16C6NeveFn-4Jfl9ccty0QS6s/edit#gid=0" target="_blank"><button class="btn">Посмотреть список наших клиентов</button></a>
        </div>
  </div>

  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  props: {
    msg: String
  }
}
</script>


<style scoped>

.section-4 .slider.responsive {
    margin-top: 40px;
}

.site-width {
    width: 100%;
    max-width: 1170px;
    position: relative;
    margin: 0 auto;
    padding: 0 10px;
}

div{
  font-size: 18px;
  color: #fff;
  font-family: Times New Roman,Georgia,Serif;
  font-weight: 400;
}

body {
    font-size: 18px;
    color: #fff;
    background: #282828;
    font-family: Times New Roman,Georgia,Serif;
    font-weight: 400;
}

.btn {
    padding: 14px;
    min-height: 48px;
    border-radius: 10px;
    background-color: #f4b859;
    border: 1px solid #f4b859;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: #282828;
    cursor: pointer;
}

input[type=text], input[type=email] {
    padding: 25px;
    width: 100%;
    max-width: 380px;
    height: 76px;
    border-radius: 10px;
    background-color: #acacac;
    text-align: center;
    color: #e4e4e4;
    font-size: 20px;
    line-height: 24px;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input, button, textarea {
    outline: none;
    font-family: Times New Roman,Georgia,Serif;
    font-size: 16px;
}

p, h2{
  color:white;
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 20pt;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}

.nmart{
  color:#f4b859;
  font-style: normal;
}
.text-center{
  margin-left: auto;
  margin-right: auto;
  width: 16em;
   
}
</style>
